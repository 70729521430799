import PropTypes from 'prop-types';
import logo_light from '../../assets/logo-light.svg';
import logo_dark from '../../assets/logo-dark.svg';

export function BrandLogo({ label }) {
    return (
        <picture>
            <source id="logo-dark" media="print" srcSet={logo_dark} />
            <source id="logo-light" media="screen" srcSet={logo_light} />
            <img src={logo_dark} data-src={logo_light} alt={label} title={label} />
        </picture>
    )
}

BrandLogo.propTypes = {
    label: PropTypes.string,
}