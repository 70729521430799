import { useRouteLoaderData } from "react-router-dom";
import { DeferredSite, BrandHeader, PageFooter } from '..';

export function SiteContainer() {
    const { deferred } = useRouteLoaderData('root');

    return (
        <>
            <a href="#main-content" className="screenreader-text">Skip to main content</a>
            <div className="page-shell page-container">
                <section id="header-content" className="header-section">
                    <BrandHeader />
                </section>
                <section className="main">
                    <DeferredSite promised={deferred} />
                </section>
                <footer id="footer-content" className="footer-section" aria-label="Page">
                    <PageFooter />
                </footer>
            </div>
        </>
    );
}