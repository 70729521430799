import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { DeferredProvider, LoadingMenu, MenuItemLink } from '../../';
import { urlPrefixed } from '../../../support/api/service';

export function DeferredMenu({ promised }) {
    const { pathname } = useLocation();

    const menuDataHandler = (deferred) => {
        const { data: menuLinkDataItems = [] } = deferred;
        const topLevelLinkDataItems = menuLinkDataItems.filter(({ attributes }) => !attributes.parent && attributes.enabled);

        menuLinkDataItems.forEach(item => {
            // remove url prefix from menu links
            let alias = item?.attributes?.link?.alias;
            if (alias) item.attributes.link.alias = alias.replace(urlPrefixed.pattern(), '');

            // build menu hierarchy
            item.descendants = menuLinkDataItems.filter(({ attributes }) => attributes.parent === `menu_link_content:${item.id}` && attributes.enabled);
        });

        return (
            <>
                {
                    topLevelLinkDataItems
                        .map(({ id, attributes, descendants }) => <MenuItemLink
                            key={id} attributes={attributes}
                            descendants={descendants}
                            activeCondition={(match) => pathname.startsWith(match)}
                            matchOn={attributes.link.alias} />)
                }
            </>
        )
    }

    return (
        <DeferredProvider
            fallback={<LoadingMenu />}
            promised={promised}
            errorElement={<div>error loading deffered menu data for site</div>}
            handler={menuDataHandler}
            />
    )
}

DeferredMenu.propTypes = {
    promised: PropTypes.object,
}