import PropTypes from 'prop-types';
import { Outlet } from "react-router-dom";
import { DeferredProvider, LoadingPage, MenuContainer } from '..';

export function DeferredSite({ promised }) {
    const menuDataHandler = () => {
        return (
            <>
                <MenuContainer />
                <div id="body-content" className="body-section">
                    <Outlet />
                </div>
            </>
        )
    }

    return (
        <DeferredProvider
            fallback={<LoadingPage />}
            promised={promised}
            errorElement={<div>error loading deffered menu data for site</div>}
            handler={menuDataHandler}
            />
    );
}

DeferredSite.propTypes = {
    promised: PropTypes.object,
}