import { useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { Accordion } from "./Accordion";
import { domain } from "../../support/api/settings";
import { replace } from "../../support/tools/helpers";
import { convertExternalLinks } from '../../support/client/special-events';
import { urlPrefixed } from '../../support/api/service';

export function PageContent({ body = '', children = () => null }) {
    const elemRef = useRef();
    const { pathname } = useLocation();

    // apply content event interactions here
    useEffect(() => {
        // add accessibility and ux qualities to external links
        convertExternalLinks(elemRef.current);
    }, [pathname, elemRef]);


    // pass children if used as a wrapper component
    if (!body) {
        return (
            <section ref={elemRef} data-html="0" className="page-contents-inner with-child">{children}</section>
        )
    }

    // clean up HTML content for referenced media files
    // set relative media links to be sourced from GC website
    const replacementList = [
        /(?<=["'])(\/sites\/default\/files\/)/g,
        /(?<=["'])(\/system\/files\/images\/)/g,
        /(?<=["'])(\/media\/)/g,
        /(?<=["'])(\/secretariat\/)/g,
    ];
    body = replace
        // replaces unintended local links with external links
        .withList(body, replacementList, domain)
        // alters public urls for prefixed links
        .replace(urlPrefixed.pattern(), '');

    return (
        <section
            ref={elemRef}
            data-html="1"
            className="page-contents-inner"
            >
                {
                parse(body, {
                        replace: (doc) => {
                            const { attribs, children: nodeChildren } = doc;
                            if (! attribs || !attribs?.class) return;
                            if (! nodeChildren || nodeChildren.length < 1) return;

                            // when found, replaces desired html element with Accordion component
                            if (attribs.class.includes('template--accordion')) {
                                return Accordion.parseHtml(nodeChildren);
                            }

                            return;
                        }
                    })
                }
        </section>
    )

}

PageContent.propTypes = {
    body: PropTypes.string,
    children: PropTypes.oneOf([
        PropTypes.children,
        PropTypes.instanceOf(null),
    ]),
}