import { Suspense } from "react";
import { Await } from "react-router-dom";
import PropTypes from 'prop-types';

export function DeferredProvider({ fallback, promised, errorElement, handler}) {
    return (
        <Suspense fallback={fallback}>
            <Await resolve={promised} errorElement={errorElement}>
                {handler}
            </Await>
        </Suspense>
    );
}

DeferredProvider.propTypes = {
    fallback: PropTypes.element,
    errorElement: PropTypes.element,
    promised: PropTypes.object,
    handler: PropTypes.func,
}