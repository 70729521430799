//import '../../assets/styles/footer.scss';
import FB_icon from '../../assets/twitter-icon.svg';
import TW_icon from '../../assets/facebook-icon.svg';

export function PageFooter() {
    return (
        <>
            <div className="copyright">&copy; Copyright 2023 Office of the Ombudsperson &mdash; All rights reserved</div>
            <div className="social-links">
                <ul>
                    <li>
                        <a href="https://prod.virtualagent.utoronto.ca/" className="text-link" target="_blank" rel="noopener noreferrer external" aria-label="Twitter Page - Opens in new window." title="UofT Mental Health Virtual Assistant">
                            <span aria-label="NAVI portal for mental health support">NAVI</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/UofTOmbuds/" target="_blank" rel="noopener noreferrer external" aria-label="Facebook Page - Opens in new window.">
                            <img src={TW_icon} className="social-icon" alt="Twitter Page" title="Twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/uoftombuds" target="_blank" rel="noopener noreferrer external" aria-label="Twitter Page - Opens in new window.">
                            <img src={FB_icon} className="social-icon" alt="Facebook Page" title="Facebook" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}