import PropTypes from 'prop-types';
import { Outlet, useLoaderData } from 'react-router-dom';

export function SuccessDisplay({ name, children, ...params })
{
    const data = useLoaderData();
    console.log(name, 'success', {
        "params": params,
        "data" : data,
    });

    return (
        <header className="success-wrapper">
            <p>{name}</p>
            <textarea defaultValue={JSON.stringify(data, null, 2)}></textarea>
            <Outlet />
            {children}
        </header>
    )
}

SuccessDisplay.propTypes = {
    name: PropTypes.string,
    children: PropTypes.any,
}