
// specific events

/**
 * Toggles the mobile menu on click
 * @returns {never}
 */
export const mobileMenuBtnClick =  () => {
    const menuList = document.querySelector('.menu-list');
    menuList.classList.toggle('menu-open');

    const closeMobileMenuByEscKey = (e) => {
        if (e.code === 'Escape') {
            mobileMenuClose();
        }
    }

    const closeMobileMenuByDocClick = (e) => {
        // NOTE: rare case may occur where the user's screen size expands to large+, click the page, and change size back to mobile.
        // the mobile menu may remain open, but body clicks won't close it anymore, since the previous click (above) would expire this event.
        if (e.target?.id === 'body-content') {
            mobileMenuClose();
        }
    }

    if (menuList.classList.contains('menu-open')) {
        document.addEventListener('keydown', closeMobileMenuByEscKey, {
            once: true,
        });

        document.querySelector('#body-content').addEventListener('click', closeMobileMenuByDocClick, {
            once: true,
        })
    }
}

/**
 * Closes the mobile menu on keypress
 * @returns {never}
 */
export function mobileMenuClose() {
    const menuList = document.querySelector('.menu-list');
    menuList.classList.remove('menu-open');
}

/**
 * Set external links to open in new tab/window
 * @param {Document | Node} element
 * @returns {never}
 */
export const convertExternalLinks = (element) => {
    const links = element.querySelectorAll('a');
    const icon = document.querySelector('#external-link-icon');
    links.forEach(a => {
        const firstChar = a.getAttribute('href').trim().substr(0, 1);
        if (firstChar !== '/' && firstChar !== '#') {

            // basic attributes
            a.setAttribute('target', '_blank');
            a.classList.add('external');

            // attributes for accessibility
            a.setAttribute('rel', 'external');
            a.setAttribute('aria-description','Opens in new window.');

            // attribute for displaying external icon
            if (icon && icon.href) {
                a.setAttribute('style',`--bg-icon: url('${icon.href}');`);
            }
        }
    });
}

/**
 * Provides accordion functionality
 * @param {Event} e
 * @returns {never}
 */
export const accordionActions = (e) => {
    const elem = e.target;

    if (elem && elem.classList.contains('accordion-trigger')) {
        const expanded = elem.getAttribute('aria-expanded') === 'true';
        const accordion = elem.parentNode;
        const content = accordion.querySelector('.accordion-content');

        if (expanded) {
            elem.setAttribute('aria-expanded', 'false');
            content.classList.add('accordion-closed');
        }
        else {
            elem.setAttribute('aria-expanded', 'true');
            content.classList.remove('accordion-closed');
        }

        e.preventDefault();
    }
}
