import { RouterProvider } from "react-router-dom";
import { router } from "../routes/router";
import { run } from "../support/tools/helpers";
import './App.css';

function App() {

  // remove environment check in production
  run.whenLessThan('production', () => {
    console.log('app-env:',import.meta.env?.VITE_ENV);
    console.log('app-mode:',import.meta.env.MODE);
  });

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
