import { PageError } from ".."

export function PageServiceFailureError() {
    return (
        <PageError
            title="Sorry!"
            errorCode={400}
            pageTitle="Service Failure"
            className="single-page node service-failure" >
            <p>We apologize for the inconvenience, but we ran into an unexpected error while you were browsing our site.</p><p>We are looking into it - please try again later.</p>
        </PageError>
    )
}