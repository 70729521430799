
import { useLoaderData, useLocation } from "react-router-dom";
import { DeferredPage, Breadcrumbs, HeroBanner } from '..';
import external_link_icon from '../../assets/external-link.svg';

export function PageContainer() {
    const { deferred } = useLoaderData();
    const { pathname } = useLocation();
    const isHomePage = pathname === '/';

    // trigger on whether to disble home banner (remove to enable)
    const disableHomeBanner = true;

    return (
        <div className="deferred page-wrapper" itemScope>
            {!disableHomeBanner && isHomePage && <HeroBanner />}
            <link itemProp="icon-url" id="external-link-icon" href={external_link_icon} />
            <div className="container">
                <Breadcrumbs />
                <div className="page-grid page">
                    <DeferredPage promised={deferred} />
                </div>
            </div>
        </div>
    );
}