import { PageError } from ".."

export function PageNotFoundError() {
    return (
        <PageError
            title="Oops!"
            errorCode={404}
            pageTitle="Page Not Found"
            className="single-page node not-found" >
            <p>We apologize for the inconvenience, but the page you are looking for may have been moved or no longer exists.</p>
            <p>We are looking into it - please try again later.</p>
        </PageError>
    )
}