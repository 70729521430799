import { Link } from 'react-router-dom';
import { BrandLogo } from './BrandLogo';


export function BrandHeader() {
    const logoLabel = "University of Toronto - Office of the Ombudsperson";

    return (
        <nav id="brand-header" className="brand-header" aria-label="Sites">
            <div className="logo-wrapper">
                <Link to="/" title={logoLabel}>
                    <BrandLogo label={logoLabel} />
                </Link>
            </div>
            <div className="uoft-link">
                <a href="https://utoronto.ca" rel="external" title="University of Toronto - Main Website">U of T Home</a>
            </div>
        </nav>
    )
}