import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { trim, run } from "../tools/helpers";
import { domain } from './settings';
import axios from "axios";

// initial preps
const pageFields = 'path,title,status,body,changed,moderation_state,publish_on,unpublish_on,field_meta_tags,drupal_internal__nid'.split(',');
const menuFields = 'link,title,enabled,parent,changed'.split(',');
const adminUnitValue = '4044ce9b-6853-4d82-a05b-03246b4450ce';

// declare new ombuds prefix for URL alteration
export const urlPrefixed = {
    /** @param {string} text */
    text: 'ombudsperson/page/',

    /**
     * Custom method for easy RegExp matching
     * @param {string|null|undefined} before
     * @param {string|null|undefined} after
     * @param {string} flags
     * @returns
     */
    pattern(before, after, flags = 'g') {
        const pattern = [before, this.text, after].filter(a => a).join('');
        return new RegExp(pattern, flags);
    }
}


/**
 * Service to perform fetch call for given api endpoint
 * @param {string} endpoint
 * @returns {{
 *  toString: {Function:string},
 *  fetch: {Function:Promise},
 * }}
 */
export function Api(endpoint) {
    let url = trim(domain) + '/' + trim(endpoint);

    return {
        toString() {
            return url;
        },
        async fetch() {
            // const res = await fetch(url);
            // if (res.status !== 200) {

            //     run.whenOn('local', () => console.error('error', this.url, res));

            //     throw new Response(this.url, {
            //         status: res.status,
            //         statusText: res.statusText,
            //     });
            // }

            // return res.json();

            const res = await axios.get(url);
            if (res.status !== 200) {
                run.whenOn('local', () => console.error('error', this.url, res));
                throw new Response(this.url, {
                    status: res.status,
                    statusText: res.statusText,
                });
            }
            return res.data;
        }
    }
}

/**
 * Helper to ease the creation of api requests for ombuds data
 * @type {{
 *  menu: {Function:string},
 *  pageByAlias: {Function:string},
 *  pageById: {Function:string},
 *  query: {DrupalJsonApiParams},
 *  search: {Function:string},
 * }}
 */
export const endpoint = {
    get _jsonapiRoot() {
        return '/jsonapi';
    },

    /**
     * used for building JSON:API params
     * @returns {DrupalJsonApiParams}
     */
    query() {
        return new DrupalJsonApiParams();
    },

    /**
     * retrieve node page data using the uuid
     * @param {string} uid
     * @param {string} query
     * @returns {string}
     */
    pageById(uid, query = '') {
        return this._jsonapiRoot + `/node/page/${uid}?` + this.query()
            .addFields('node--page', pageFields)
            .addFilter('field_administrative_unit.id', adminUnitValue)
            .addFilter('status', 1)
            .getQueryString()
            + (
                query
                    ? `&${query}`
                    : ''
            )
    },

    /**
     * Retrieve node page data using the path alias
     * @param {string} path
     * @returns {string}
     */
    pageByAlias(path) {
        return '/router/translate-path?path=' + encodeURIComponent(path);
    },

    /**
     * Retrieve node page data using the path alias (deprecated)
     * @param {string} path
     * @returns {string}
     * @deprecated
     */
    pageByAliasX(path) {
        return `/path_alias/path_alias/?` + this.query()
            .addFilter('alias', path)
            .addFilter('status', 1)
            .getQueryString();
    },

    /**
     * Retrieve a list of menu items for ombuds pages containing uuid, status, link alias, etc.
     * @returns {string}
     */
    menu() {
        const menuName = 'ombudsperson';

        return this._jsonapiRoot + `/menu_link_content/menu_link_content?` + this.query()
            .addFilter('menu_name', menuName)
            .addFields('menu_link_content--menu_link_content', menuFields)
            .getQueryString();
    },

    /**
     * Retrieve lookup array of pages for given term
     * @param {string} term
     * @param {string | number} pageLimit
     * @param {string | number} pageNum
     * @returns {string}
     */
    search(term, pageLimit = 15, pageNum = 0) {
        const searchGroupLabel = 'search-group';
        return this._jsonapiRoot + `/node/page/?` + this.query()
            .addGroup(searchGroupLabel)
            .addFilter('title', term, 'CONTAINS', searchGroupLabel)
            .addFilter('body.value', term, 'CONTAINS', searchGroupLabel)
            .addFilter('field_administrative_unit.id', adminUnitValue)
            .addFilter('status', 1)
            .addPageLimit(pageLimit)
            .addPageOffset(pageNum * pageLimit)
            .addFields('node--page', pageFields)
            .getQueryString()
    }
}
