import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { propertyId } from './support/analytics';
import App from './app/App';
import './styles/main.scss';
//import reportWebVitals from './reportWebVitals';
//const App = React.lazy(() => import('.app/App'));

// register Google Analtyics
//analytics.init();
ReactGA.initialize(propertyId, {
  testMode: false,
});

// Load React App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
