import PropTypes from 'prop-types';
import { LoadingPage, DefaultBreadcrumbs } from "../";
import external_link_icon from '../../assets/external-link.svg';

export function LoadingPageContainer({ lines = 10 }) {
    return (
            <div className="deferred page-wrapper" itemScope>
                <link itemProp="icon-url" id="external-link-icon" href={external_link_icon} />
                <div className="container loading-container">
                    <DefaultBreadcrumbs title={'Loading'} />
                    <div className="page-grid page">
                        <LoadingPage lines={lines} />
                    </div>
                </div>
            </div>
    );
}

LoadingPageContainer.propTypes = {
    lines: PropTypes.number,
}