import PropTypes from 'prop-types';
import { LoadingMenu, BrandHeader, PageFooter } from "..";
import { LoadingPageContainer } from './LoadingPageContainer';

export function LoadingSite({ lines = 10 }) {
    return (
        <>
            <a href="#main-content" className="screenreader-text">Skip to main content</a>
            <div className="page-shell page-container loading-shell">
                <section id="header-content" className="header-section">
                    <BrandHeader />
                </section>
                <section className="main">
                    <LoadingMenu />
                    <div id="body-content" className="body-section">
                        <LoadingPageContainer lines={lines} />
                    </div>
                </section>
                <footer id="footer-content" className="footer-section">
                    <PageFooter />
                </footer>
            </div>
        </>
    );
}

LoadingSite.propTypes = {
    lines: PropTypes.number,
}