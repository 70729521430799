import PropTypes from 'prop-types';
// import ReactGA from 'react-ga4';
import { useLocation, useRouteError } from 'react-router-dom';
import { DeferredProvider, LoadingPage, PageMetaTags, PageContent, PageHeading, SubmenuContainer, PageServiceFailureError } from '..';

export function DeferredPage({ promised }) {
    const { pathname } = useLocation();
    const error = useRouteError();

    if (error) {
        console.error('menu async error', error);
    }

    const pageDataHandler = (deferred) => {

        const { title, body, field_meta_tags } = deferred.data.attributes;
        let content = body?.processed ?? body?.value;
        const pageTitle = pathname === '/'
            ? 'Office of the Ombudsperson'
            : title + ' - Ombudsperson';

        // send GA pageview
        // ReactGA.send({ hitType: "pageview", page: pathname, title });

        return (
            <>
                <SubmenuContainer />
                <PageMetaTags meta={field_meta_tags} fallbackTitle={pageTitle} />
                <PageHeading title={title} />
                <main id="main-content" aria-label="Main Content" className="single-page node page-contents" data-id={deferred.data.id}>
                    <PageContent body={content} />
                </main>
            </>
        )
    }

    return (
        <DeferredProvider
            fallback={<LoadingPage />}
            promised={promised}
            errorElement={<PageServiceFailureError />}
            handler={pageDataHandler}
        />
    );
}

DeferredPage.propTypes = {
    promised: PropTypes.object,
}