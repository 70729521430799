import { useLocation, NavLink } from "react-router-dom";
import { HomeIcon } from "../..";
import { slugToTitle } from "../../../support/tools/helpers";


export function Breadcrumbs() {
    const { pathname } = useLocation();

    // split URL into parts for processing
    const items = [];
    const parts = pathname.split('/');

    // use while loop for better performance and to fix loop bug (missing breadcrumb items)
    while (parts.length > 1) {
        items.push(parts.join('/'));
        parts.pop();
    }

    // if missing initial forward slash, add it
    if (items.length && items[0] !== '/') items.push('/');

    return (
        <nav className="breadcrumbs list-reset" aria-label="Breadcrumbs">
            <ol>
                {items.reverse().map((item, i) => {
                    if (i === items.length - 1) {
                        return (<li key={item} data-item={item}><a aria-current="location" href="#">{slugToTitle(item, () => <HomeIcon />)}</a></li>);
                    }
                    else
                        return (
                            <li key={item} data-item={item}>
                                <NavLink to={item}>
                                    {slugToTitle(item, () => <HomeIcon />)}
                                </NavLink>
                            </li>
                        );
                })}
            </ol>
        </nav>
    );
}