import React from "react";
import { homePageLoader, nodeByAliasLoader, menuLoader, searchLoader } from "./loaders";
import { createBrowserRouter, createRoutesFromElements, Route, Outlet, useLocation, useNavigationType, matchRoutes } from "react-router-dom";
import { LoadingSite, SiteContainer, PageNotFoundError, PageServiceFailureError, PageContainer } from "../components";
import { LoadingPageContainer } from "../components/loading/LoadingPageContainer";
import * as Sentry from "@sentry/react";
import { httpClientIntegration } from "@sentry/integrations";

const routes = createRoutesFromElements(
    <>
        <Route path="/"
            id="root"
            element={<SiteContainer />}
            loader={menuLoader}
            error={<div className="main site-error menu-loading-error"><Outlet /></div>}>

            <Route
                index
                element={<PageContainer />}
                loader={homePageLoader}
                errorElement={<PageServiceFailureError />} />

            <Route
                id="search"
                path="search"
                element={<PageNotFoundError />}
                loader={searchLoader}
                errorElement={<PageServiceFailureError />} />

            <Route
                id="not-found"
                path="not-found"
                element={<PageNotFoundError />}
                errorElement={<PageServiceFailureError />} />

            <Route
                id="loading"
                path="loading"
                element={<LoadingPageContainer />}
                errorElement={<PageServiceFailureError />} />


            <Route
                id="catch"
                path="*"
                element={<PageContainer />}
                loader={nodeByAliasLoader}
                errorElement={<PageNotFoundError />} />

            <Route
                id="error"
                path="error"
                element={<PageServiceFailureError />}
                errorElement={<PageNotFoundError />} />
        </Route>
        <Route
                id="load"
                path="load"
                element={<LoadingSite />}
                errorElement={<PageServiceFailureError />} />
    </>
);

Sentry.init({
    dsn: "https://d92caa5c219730f7bbc6cf1a5a078e6a@o4506752247726080.ingest.sentry.io/4506752267845632",
    environment: import.meta.env?.VITE_ENV ?? import.meta.env.MODE,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromElements,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
        }),
        httpClientIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracesSampleRate: 0.1,
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/thankful-sand-09db60010.+?\.azurestaticapps.net/,
        /^https:\/\/ombudsperson.utoronto.ca/
    ],
    debug: false,
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
});
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);