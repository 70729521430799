import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export function MenuItemLinkLabel({ active, url, label }) {
    const classNames = 'menu-item-link ' + (active ? 'active' : '');
    const closeMenu = (e) => {
        const openClass = 'menu-open';
        const menu = e.target.closest('.menu-list');
        if (menu && menu.classList.contains(openClass)) {
            menu.classList.remove(openClass);
        }
    };

    return (
        <NavLink
            to={url}
            className={classNames}
            onClick={(e) => closeMenu(e)}
            onKeyDown={(e) => ['Space','Enter'].includes(e.code) && closeMenu(e)}
            >{label}</NavLink>
    )
}

MenuItemLinkLabel.propTypes = {
    active: PropTypes.bool,
    url: PropTypes.string,
    label: PropTypes.string,
}