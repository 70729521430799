// todo: for local development - can remove
if (! import.meta.env) {
    import.meta.env = {
        'VITE_ENV': 'prod'
    };
}

// domain settings
export let domain = '';
switch (import.meta.env?.VITE_ENV ?? import.meta.env.MODE) {
    case 'local':
        domain = import.meta.env?.VITE_DOMAIN ?? "http://ogc.localhost:8888/";
        break;
    case 'dev':
    case 'development':
        domain = "https://d9up-the-office-of-the-governing-council.pantheonsite.io/";
        break;
    case 'test':
    case 'testing':
        domain = "https://dev-the-office-of-the-governing-council.pantheonsite.io/";
        break;
    case 'staging':
        domain = "https://test-the-office-of-the-governing-council.pantheonsite.io/";
        break;
    default:
    case 'prod':
    case 'production':
        domain = "https://governingcouncil.utoronto.ca/";
        break;
}

if (import.meta.env?.MODE !== 'production') {
    console.log('domain', domain, import.meta.env.MODE);
}

export default {
    domain,
};
