import { defer } from "react-router-dom";
import { Api, endpoint, urlPrefixed } from "../support/api/service";
import { trim, wait, run } from "../support/tools/helpers";

// option to artificially delay api calls (mainly for testing purposes)
// set to 0 to remove any artificial delays
// ================================================
const DELAY_AMOUNT_IN_SECONDS = 0;
// ================================================

/**
 * Retrieves menu related data from OGC Website API
 * @returns {DeferredData}
 */
export const menuLoader = async () => {
    return defer({
        deferred: wait(async () => await Api(endpoint.menu()).fetch(), DELAY_AMOUNT_IN_SECONDS),
    });
}

/**
 * Retrieves node related data by UUID from OGC Website API
 * @param {{params:{id:string}}|{params:{'*':string}}} param0
 * @returns {DeferredData}
 */
export const nodeByIdLoader = async ({ params = { id: 0 } }) => {
    const id = params?.id ?? params['*'];

    run.whenNot('production', () => console.log('load by id', params));

    return defer({
        deferred: wait(async () => await Api(endpoint.pageById(id)).fetch(), DELAY_AMOUNT_IN_SECONDS),
    });
}

/**
 * Retrieves search related data by query metric from OGC Website API
 * @param {{request:{url:{searchParams:URLSearchParams}}}} param0
 * @returns {DeferredData}
 */
export const searchLoader = async ({ request }) => {
    const url = new URL(request.url);
    const
        term = url.searchParams.get('q') ?? '',
        limit = url.searchParams.get('limit') ?? 15,
        page = url.searchParams.get('page') ?? 0;

    // if no term is passed, return empty results set
    if (!term) {
        return defer({
            deferred: wait(async () => new Response(({
                data: [],
                links: {
                    self: {},
                    prev: {}
                }
            })), DELAY_AMOUNT_IN_SECONDS)
        });
    }

    // otherwise send api request
    return defer({
        deferred: wait(async () => await Api(endpoint.search(term, limit, page)).fetch(), DELAY_AMOUNT_IN_SECONDS),
    });
}

/**
 * Retrieves homepage node related data by UUID from OGC Website API
 * @returns {Promise<DeferredData>}
 */
export const homePageLoader = async () => {
    const params = { id: '9a890587-3f91-4c00-a3c5-f30e8b0faf00' };
    return await nodeByIdLoader({ params });
}

/**
 * Retrieves node related data by alias path from OGC Website API
 * @param {{params:{'*':string}}} param0
 * @returns {DeferredData}
 * @throws {Error}
 */
export const nodeByAliasLoader = async ({ params }) => {
    // extract url alias from param
    // replace any existing prefixes in the url - this is a safe measure to ensure the real URLs works as well
    const pageAlias = trim(params['*']).replace(urlPrefixed.pattern('^'), '');

    // determine full path for query
    const path = '/' + urlPrefixed.text + pageAlias;

    let res;

    try {
        res = await Api(endpoint.pageByAlias(path)).fetch();

        run.whenNot('production', () => console.log('alias lookup', res));

        const uuid = res?.entity?.uuid;
        if (uuid) {
            const promise = async () => await Api(endpoint.pageById(uuid)).fetch();

            //throw new Error('Fake error test for sentry');

            return defer({
                deferred: wait(promise, DELAY_AMOUNT_IN_SECONDS),
            })
        }
    }
    catch (err) {
        run.whenNot('production', () => console.log('load node by alias error', err));
        throw (err);
    }

    throw new Error('Path not found')
}