import PropTypes from 'prop-types';

export function PageHeading({ title }) {
    return (
        <header id="main-header">
            <h1>{title}</h1>
        </header>
    )
}

PageHeading.propTypes = {
    title: PropTypes.string,
}