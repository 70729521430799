import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { DeferredProvider, MenuItemLink } from '../../';

export function DeferredSubmenu({ promised }) {
    const { pathname } = useLocation();

    const menuDataHandler = (deferred) => {
        const { data: menuLinkDataItems = [] } = deferred;

            // build descendant submenu items list for each menu item
            menuLinkDataItems.forEach(item => {
                item.descendants = menuLinkDataItems.filter(({ attributes }) => attributes.parent === `menu_link_content:${item.id}` && attributes.enabled);
            });

            const topLevelLinkDataItems = menuLinkDataItems.filter(({ attributes }) => !attributes.parent);
            const currentLinkDataItem = topLevelLinkDataItems.filter(({ attributes }) => pathname.startsWith(attributes.link.alias));
            const submenuLinkDataItems = currentLinkDataItem.length
                ? currentLinkDataItem[0].descendants
                : [];

            if (!submenuLinkDataItems.length) {
                return null;
            }

            return (
                <nav className="submenu-list" aria-label="Submenu">
                    <ul className="list-reset" role="menu">
                        {
                            submenuLinkDataItems
                                .map(({ id, attributes, descendants }) => <MenuItemLink
                                    key={id}
                                    attributes={attributes}
                                    descendants={descendants}
                                    activeCondition={(match) => pathname.startsWith(match)}
                                    matchOn={attributes.link.alias} />)
                        }
                    </ul>
                </nav>
            )
        }

    return (
        <DeferredProvider
            fallback={<></>}
            promised={promised}
            errorElement={<div>error loading deffered menu data for site</div>}
            handler={menuDataHandler}
            />
    )
}

DeferredSubmenu.propTypes = {
    promised: PropTypes.object,
}