import PropTypes from 'prop-types';
import { MenuItemLinkLabel } from '../..';
import { useEffect, useRef } from 'react';
import { addClickTrigger } from '../../../support/client/events';


export function MenuItemLink({ attributes, descendants = [], activeCondition = () => false, matchOn = '' }) {
    const btnRef = useRef();
    const url = attributes.link.alias;
    const label = attributes.title;
    const accessibleLabels = {
        open: 'Expand child links',
        close: 'Collapse child links',
    }

    useEffect(() => {
        const toggleBtn = btnRef.current;

        if (toggleBtn && !toggleBtn.dataset.processed) {
            toggleBtn.dataset.processed = 1;
            const parent = toggleBtn.closest('.link-control');

            addClickTrigger(btnRef.current, () => {
                const srLabel = toggleBtn.querySelector('.sr-only');
                const toggledState = toggleBtn.getAttribute('aria-expanded') === 'true'
                    ? 'false'
                    : 'true';

                toggleBtn.setAttribute('aria-expanded', toggledState);
                parent.setAttribute('aria-expanded', toggledState);

                // display instructions for accessibility
                if (toggledState === 'true') {
                    srLabel.textContent = accessibleLabels.close;
                }
                else {
                    srLabel.textContent = accessibleLabels.open;
                }

                // console.log('menu-trigger', toggledState);
            });
        }
    }, []);

    return (
        <li>
            {
                (descendants.length > 0)
                    ? (
                        <>
                            <div className="link-control" aria-expanded="false" aria-haspopup="true">
                                <MenuItemLinkLabel active={activeCondition(matchOn)} url={url} label={label} />
                                <button ref={btnRef}
                                    className="toggle-descendants button-reset"
                                    type="button"
                                    aria-expanded="false"
                                    aria-description="Toggles submenu dropdown">
                                    <span></span>
                                    <span></span>
                                    <span className="sr-only">{accessibleLabels.open}</span>
                                </button>
                            </div>

                            <div className="descendants">
                                <ul className="list-reset">
                                    {
                                        descendants
                                            .map(({ id, attributes, descendants }) => <MenuItemLink
                                                key={id}
                                                attributes={attributes}
                                                descendants={descendants}
                                                activeCondition={activeCondition}
                                                matchOn={attributes.link.alias} />)
                                    }
                                </ul>
                            </div>
                        </>
                    )
                    : (<MenuItemLinkLabel active={activeCondition(matchOn)} url={url} label={label} />)
            }
        </li>
    )
}

MenuItemLink.propTypes = {
    attributes: PropTypes.object,
    descendants: PropTypes.array,
    activeCondition: PropTypes.func,
    matchOn: PropTypes.string,
}