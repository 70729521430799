import ReactGA from 'react-ga4';

// declare google analytics 4 measurement ID
export const propertyId = 'G-XH31WRN82M';

// wrap analytics in wrapper to keep implimentation vendor-agnostic
export const analytics = {
    init: (testMode = false) => ReactGA.initialize(propertyId, {testMode}),

    hitContactFormCta: (location, interactionHitType = 'click') => ReactGA.event({
        action: 'Hit Request Form CTA Button',
        category: location,
        label: interactionHitType,
    })
}

export default analytics;
