import { useRef } from "react";
import { LoadingPlaceholder } from ".."
import { mobileMenuBtnClick } from "../../support/client/special-events";

export function LoadingMenu() {
    const btnRef = useRef();
    const menuRef = useRef();

    return (
        <nav className="menu-list block-list loading-menu">
            <div className="menu-ctrl-bar">
                <span className="menu-title">
                    <span className="display-text">Menu</span>
                </span>
                <button type="button" onClick={mobileMenuBtnClick} ref={btnRef} className="button-reset menu-toggle-btn" aria-label="Toggle Mobile Menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <ul ref={menuRef} className="list-reset loading-menu-list" role="menu">
                {
                    Array(5).fill(1).map((o, i) => (
                        <li key={i}><LoadingPlaceholder /></li>
                    ))
                }
            </ul>
        </nav>
    )
}