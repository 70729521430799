import { LoadingPlaceholder } from ".."
import PropTypes from 'prop-types';

export function LoadingPage({ lines = 10 }) {
    return (
        <>
            <header id="main-header">
                <h1>
                    <LoadingPlaceholder />
                </h1>
            </header>
            <section id="main-content" className="page-contents loading-page page">
                {Array(lines).fill(0).map((o,i) => <LoadingPlaceholder key={i} />)}
            </section>
        </>
    );
}

LoadingPage.propTypes = {
    lines: PropTypes.number,
}