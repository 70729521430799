//import { useRef } from 'react';
import PropTypes from 'prop-types';
import { domToReact } from 'html-react-parser';
import { accordionActions } from '../../support/client/special-events';

export function Accordion({ title, contents, children }) {
    return (
        <div className="template--accordion component">
            <h2 aria-expanded="false"
                //aria-label="Click to show the content"
                className="accordion-closed accordion-trigger"
                role="button"
                onClick={(e) => {
                    accordionActions(e);
                    //console.log('accordion-click',e.target);
                }}
                onKeyDown={(e) => {
                    if (['Space','Enter'].includes(e.code)) {
                        accordionActions(e);
                    }
                    //console.log('accordion-key',e.target, e.code, ['Space','Enter'].includes(e.code));
                }}
                tabIndex="0">{title}</h2>
            <div className="accordion-closed accordion-content">
                {(children || contents)}
            </div>
        </div>
    )
}

Accordion.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    contents: PropTypes.array,
    children: PropTypes.oneOf([
        PropTypes.children,
        PropTypes.instanceOf(null),
    ]),
}


Accordion.parseHtml = function (nodes) {
    let accordionTitle, accordionChildren;

    nodes.forEach(node => {
        if (! node?.attribs?.class) return;

        if (node.attribs.class.includes('accordion-trigger')) {
            accordionTitle = domToReact(node.children);
            return;
        }

        if (node.attribs.class.includes('accordion-content')) {
            accordionChildren = domToReact(node.children);
            return;
        }
    })

    return <Accordion title={accordionTitle} contents={accordionChildren} />;
}