import PropTypes from 'prop-types';
import { HomeIcon } from "../..";

export function DefaultBreadcrumbs({ title }) {
    return (
        <div className="breadcrumbs list-reset default">
            <ul>
                <li><a href="/" title="Home"><HomeIcon /></a></li>
                <li><span>{title}</span></li>
            </ul>
        </div>
    )
}

DefaultBreadcrumbs.propTypes = {
    title: PropTypes.string,
}