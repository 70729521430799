import { useRouteError } from "react-router-dom";
import PropTypes from 'prop-types';

export function ErrorDisplay({ msg, children })
{
    let error = useRouteError();
    console.log('error',error);

    return (
        <div className="error-display">
            <p>{msg}</p>
            {children}
        </div>
    )
}

ErrorDisplay.propTypes = {
    msg: PropTypes.string,
    children: PropTypes.any,
}