// generic events

/**
 * Add click and keyboard event handlers to a DOM Node (for accessbility)
 * @param {Node} elem
 * @param {Function} fn
 * @returns {never}
 */
export const addClickAndKeyboardTriggers = (elem, fn) => {
    addClickTrigger(elem, fn);
    elem.addEventListener('keydown', (e) => {
        if (e.code === 'Space' || e.code === 'Enter') {
            e.target.click();
        }
    });
}

/**
 * Add a click event handler to a DOM Node
 * @param {Node} elem
 * @param {Function} fn
 * @returns {never}
 */
export const addClickTrigger = (elem, fn) => {
    elem.addEventListener('click', fn);
}
