
/**
 * Function to clean leading and trailing slashes from urls
 * @param {string} path
 * @returns {string}
 */
export const trim = (path) => path.replace(/(^\/|\/$)/g, '');

/**
 * Group of functions for replacing text in strings (or collection of strings)
 * @type {{
 *  withText: {Function:string},
 *  withList: {Function:string},
 * }}
 */
export const replace = {
    /**
     * Specify text replacements for a body of text
     * @param {string} haystackText
     * @param {string | RegExp} needleText
     * @param {string} replacementText
     * @returns {string}
     */
    withText(haystackText, needleText, replacementText) {
        const pattern = (needleText instanceof RegExp) ? needleText : new RegExp(`(${needleText})`, "g");
        return haystackText.replace(pattern, replacementText + "$1");
    },

    /**
     * Specify a list of text replacements for a body of text
     * @param {string} haystackText
     * @param {array | string} needleList
     * @param {string} replacementText
     * @returns {string}
     */
    withList(haystackText, needleList, replacementText) {
        if (typeof needleList === 'string')
            return this.withText(haystackText, needleList, replacementText);

        if (Array.isArray(needleList)) {
            needleList.forEach(needle => {
                haystackText = this.withText(haystackText, needle, replacementText);
            });
        }

        return haystackText;
    }
}

/**
 * Clean up html text
 * @param {string} haystackText
 * @param {string | RegExp} needleText
 * @param {string} replacementText
 * @returns {string}
 * @deprecated
 */
export function replaceText(haystackText, needleText, replacementText) {
    const pattern = new RegExp(`(${needleText})`, "g");

    run.whenLessThan('testing', () =>
        console.log('pattern', needleText, pattern)
    );

    return haystackText.replace(pattern, replacementText + needleText);
}

/**
 * Throttles a promise by a given amount of seconds
 * @param {Promise} promise
 * @param {number} [seconds=5]
 * @returns {Promise}
 */
export function wait(promise = async () => null, seconds = 5) {
    return new Promise((resolve) => {
        setTimeout(async () => {
            run.whenNot('production', () => {
                if (seconds > 1) console.log('waited long enough');
            });

            resolve(await promise());
        }, seconds * 1000);
    })
}

/**
 * Breaks down urls for working with breadcrumbs
 * @param {string} text
 * @param {Function} fnHomeDisplay
 * @returns {string}
 */
export const slugToTitle = (text, fnHomeDisplay = () => 'Home') => {
    let lastItem = text.split('/').pop();
    if (!lastItem) {
        lastItem = fnHomeDisplay();
    }
    else {
        lastItem = lastItem
            .replaceAll('-', ' ')
            .replace(/\w\S*/ig, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .replace(/and|but|for|of|in|on|with|the/ig, (txt) => txt.toLowerCase())
            .replace(/Ut[mscg]{1,2}/g, (txt) => txt.toUpperCase())
            ;
    }
    return lastItem;
}

/**
 * Invoke functions based on environment
 * @type {{
 *  whenOn: {Function: never},
 *  whenNot: {Function: never},
 *  whenLessThan: {Function: never},
 *  whenGreaterThan: {Function: never},
 * }}
 */
export const run = {
    _levels: {
        prod: 0,
        production: 0,
        staging: 1,
        qa: 1,
        testing: 2,
        dev: 3,
        develop: 3,
        development: 3,
        local: 4,
    },

    /**
     * Get the number value (level) for a target environment
     * @param {string | number} env
     * @returns {number}
     */
    _getLevel(env) {
        return this._levels[env] ?? 5;
    },

    /**
     * Get the number values(s) for a target environment and the current environment
     * @param {string | number} env
     * @returns {{ target: number, current: number }}
     */
    _getLevelsForComparison(env) {
        return {
            target: (typeof env === 'number') && (env % 1 === 0) ? env : this._getLevel(env),
            current: this._getLevel(import.meta.env.MODE)
        };
    },

    /**
     * Invokes a given function when the given condition is true
     * @param {boolean} condition
     * @param {Function} fn
     * @param {array} args
     * @returns {never}
     */
    _invokeWhen(condition, fn, args) {
        if (condition) fn(...args);
    },

    /**
     * Invokes a given function when the current environment matches the target
     * @param {string | number} env
     * @param {Function} fn
     * @param {array} args
     * @returns {never}
     */
    whenOn(env, fn, args = []) {
        const {target, current} = this._getLevelsForComparison(env);
        this._invokeWhen(current === target, fn, args);
    },

    /**
     * Invokes a given function when the current environment does not match the target
     * @param {string | number} env
     * @param {Function} fn
     * @param {array} args
     * @returns {never}
     */
    whenNot(env, fn, args = []) {
        const {target, current} = this._getLevelsForComparison(env);
        this._invokeWhen(current !== target, fn, args);
    },

    /**
     * Invokes a given function when the current environment's level is lesser than the target's
     * @param {string | number} env
     * @param {Function} fn
     * @param {array} args
     * @returns {never}
     */
    whenLessThan(env, fn, args = []) {
        const {target, current} = this._getLevelsForComparison(env);
        this._invokeWhen(current < target, fn, args);
    },

    /**
     * Invokes a given function when the current environment's level is greater than the target's
     * @param {string | number} env
     * @param {Function} fn
     * @param {array} args
     * @returns {never}
     */
    whenGreaterThan(env, fn, args = []) {
        const {target, current} = this._getLevelsForComparison(env);
        this._invokeWhen(current > target, fn, args);
    },
}
