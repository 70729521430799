import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { DefaultBreadcrumbs, PageHeading } from '..';
import external_link_icon from '../../assets/external-link.svg';

export function PageError({ title, children, pageTitle = null, className = null, errorCode = 500 }) {
    title = title ?? 'Error';
    pageTitle = pageTitle ?? title;

    return (
        <div className={"deferred page-wrapper page-error " + className} itemScope>
            <Helmet>
                <title>{pageTitle} - Ombudsperson</title>
                <meta name='prerender-status-code' content={errorCode} />
            </Helmet>
            <link itemProp="icon-url" id="external-link-icon" href={external_link_icon} />
            <div className="container loading-container">
                <DefaultBreadcrumbs title={pageTitle} />
                <div className="page-grid page">
                    <div className="single-page loading">
                        <div className="page-grid">
                        <PageHeading title={title} />
                            <section id="main-content" className="page-contents error-page page">
                                {children}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PageError.propTypes = {
    title: PropTypes.string,
    children: PropTypes.children,
    pageTitle: PropTypes.string,
    className: PropTypes.string,
    errorCode: PropTypes.number,
}