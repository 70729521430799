import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

export function PageMetaTags({ meta, fallbackTitle = '' }) {
    meta = meta ?? {};
    const pageTitle = meta.title ?? fallbackTitle;
    return (
        <Helmet>
            <title>{pageTitle}</title>
            {meta?.description && <meta name="description" content={meta.description} />}
            {meta?.keywords && <meta name="description" content={meta.keywords} />}
            {meta?.canonical_url && <link rel="canonical" href={meta.canonical_url} />}
            {meta?.rights && <meta name="rights" content={meta.rights} />}
            {meta?.robots && <meta name="robots" content={meta.robots} />}
            {meta?.og_site_name && <meta name="og:site_name" content={meta.og_site_name} />}
            {meta?.og_title && <meta name="og:title" content={meta.og_title} />}
            {meta?.canonical_url && <meta name="og:url" content={meta.canonical_url} />}
            {meta?.description && <meta name="og:description" content={meta.description} />}
        </Helmet>
    )
}

PageMetaTags.propTypes = {
    meta: PropTypes.object,
    fallbackTitle: PropTypes.string,
}